<template>
  <b-container>
    <b-col class="mb-3" v-if="this.$i18n.locale=='fa'">
      <span class="h3 fw-bold d-block">حریم خصوصی</span>
      <span class="h6 d-block"
        >سیاست حفظ اطلاعات و محرمانگی اطلاعات کاربران مایندولوژی</span
      >
      <p class="my-1">
        ما در مایندولوژی معتقدیم اصول حفظ حریم شخصی و اطلاعات کاربران بسیار مهم
        و حساس است. در این سند اصول محرمانگی اطلاعات و نیز راهکارهای مایندولوژی
        برای حفظ حریم شخصی و اطلاعات کاربران سایت مایندولوژی توضیح داده شده
        است.<span class="text-warning">اطلاعات خصوصی ای است که مایندولوژی</span>
        از کاربران سایت جمع آوری می کند و نیز نحوه استفاده مایندولوژی از این
        اطلاعات را در این سند شرح خواهیم داد. این اطلاعات شامل مواردی است که
        مخصوص هر کاربر بوده و به کمک آن ها می توان کاربران مایندولوژی را به صورت
        منحصر به فرد شناسایی نمود. نمونه ایی از این اطلاعات عبارتند از: نام،
        آدرس، آدرس الکترونیک (ایمیل)، شماره تلفن و سایر اطلاعاتی که به صورت عادی
        در دسترس عموم مردم نیست. این سند شامل مواردی که در اختیار مایندولوژی
        نیست و مایندولوژی هیچ کنترلی بر آن ها ندارد نمی باشد. شرکت ها و کلینیک
        های مشتری خدمات مایندولوژی و افراد ثالثی که در استخدام مایندولوژی نیستند
        و همچنین اطلاعات ثبت شده در کلینیک ها و مشاورین و متخصصینی که به صورت
        رسمی در استخدام ما نیستند از جمله همین موارد هستند.
      </p>
      <span class="h6 my-1">اطلاعات جمع آوری شده و مدل استفاده از آن :</span>
      <p>
        شما با ثبت نام در برنامه کاربردی و وب سایت مایندولوژی اطلاعات خصوصی خود
        از قبیل نام، نشانی، نشانی الکترونیک و … را در اختیار مایندولوژی قرار می
        دهید. پس از ورود به سایت یا برنامه مایندولوژی شما یک کاربر ناشناخته
        نیستید و اطلاعات مربوط به استفاده شما از خدمات مایندولوژی نگهداری می
        گردد. این اطلاعات شامل وضعیت روحی شما، آزمون ها و تمرینها، مشاوره های
        روانشناختی، روان درمانی و سرویسهای مورد استفاده در این سامانه می باشد.
        مایندولوژی کلیه تراکنش های شما از جمله تراکنش های مالی در برنامه | وب
        سایت مایندولوژی را جمع آوری و نگهداری می کند. مایندولوژی به صورت خودکار
        پس از ورود شما به سایت مایندولوژی اطلاعاتی از قبیل IP شما، اطلاعات کوکی
        های مایندولوژی و صفحاتی که مشاهده می کنید را جمع آوری و نگهداری می
        کند.مایندولوژی از این اطلاعات در راستای بعضی از اهداف مثل ارتقاء خدمات
        مایندولوژی، تهیه محتوای مناسب، تعیین برنامه های ویژه به مراجعین و برنامه
        های تبلیغاتی و مانند آن استفاده می کند.
      </p>
      <span class="h6 my-1"
        >مایندولوژی اطلاعات شما را اجاره نمی دهد، نمی فروشد و به اشتراک نمی
        گذارد به جز در موارد زیر:
      </span>
      <p>
        در صورت ادغام مایندولوژی با یک شرکت دیگر که در این حالت قبل از به اشتراک
        گذاری اطلاعات شما، این موضوع به کاربران اطلاع داده شده و نظر کاربران را
        درباره ادامه استفاده از خدمات مایندولوژی یا خروج کامل جویا می شود.در
        صورت تخطی کاربران از قوانین شرایط و ضوابط سرویسها ، و در صورت وجود شاکی
        خصوصی نسبت به عملکرد شما، اطلاعات شما در اختیار مراجع قانونی قرار می
        گیرد.در صورتی که مراجع قضایی اطلاعات شما را –به هر دلیلی- از مایندولوژی
        بخواهند این اطلاعات در اختیار ایشان قرار می گیرد.مایندولوژی ممکن است بر
        روی کامپیوتر شما کوکی ذخیره کرده و در مراجعات بعدی شما به سایت
        مایندولوژی از آن استفاده کند.
      </p>
      <p>
        شما می توانید اطلاعات ثبت شده خود را در سایت مایندولوژی تغییر دهید. این
        اطلاعات شامل کلیه اطلاعاتی است که در هنگام عضویت در مایندولوژی آن ها را
        در سایت مایندولوژی وارد نموده اید؛ مگر آنها که از لحاظ شناسایی هویت برای
        کارکرد مایندولوژی ملاک امنیت حساب کاربری داشته باشد مثل ایمیل و نام
        خانوادگی و…
      </p>
      <p>
        شما می توانید به طور کامل از مایندولوژی و کلیه خدمات آن خارج شوید. در
        این حالت برخی از اطلاعات شما از مایندولوژی حذف شده و تعدادی دیگر از آن
        ها حداقل به مدت یک سال بایگانی می شوند. این اطلاعات شامل نام، نام
        خانوادگی، نشانی الکترونیک، شماره تلفن همراه، پیام های ارسالی و مانند آن
        بوده ولی محدود به موارد فوق نیست.
      </p>
    </b-col>
    <b-col class="text-left mb-3" v-else>
      <span class="h3 fw-bold">Policy</span>
      <p class="text-left my-1">
        Information policy and information confidentiality of Mindology.io users
        At Mindology.io, we believe that the principles of privacy and user
        information are very important and sensitive. This document explains the
        principles of information confidentiality as well as Mindology.io
        solutions to protect the privacy and information of Mindology.io site
        users. It is the private information that Mindology.io collects from
        site users and also describes how Mindology.io uses this information in
        this document. We will give. This information includes items that are
        specific to each user and can be used to uniquely identify mindology.io
        users. Examples of this information include names, addresses, email
        addresses, telephone numbers, and other information that is not normally
        available to the public. This document does not include items that are
        not available to Mindology.io and Mindology.io has no control over them.
        These include companies and client clinics of Mindology.io Services and
        third parties who are not employed by Mindology.io, as well as
        information registered in clinics and consultants and specialists who
        are not formally employed by us. Information collected and model of its
        use: By registering in the application and the Mindology.io website, you
        provide Mindology.io with your private information such as name,
        address, email address and.. Once you log in to the site or program, you
        are not an unknown user, and information about your use of the service
        is stored. This information includes your mental state, tests and
        exercises, psychological counseling, psychotherapy and services used in
        this system. Mindology.io of all your transactions, including financial
        transactions in the program | Collects and maintains the Mindology.io
        website. Mindology.io automatically collects and maintains information
        such as your IP, Mindology.io cookie information, and the pages you view
        after you log in to the Mindology.io site. Mindology.io provides this
        information for some purposes, such as promoting Mindology.io services
        Uses appropriate content, assigns special programs to clients and
        promotional programs and the like. Mindology.io does not rent, sell or
        share your information except in the following cases: In case of
        integration of Mindology.io with another company, in this case, before
        sharing your information, users will be informed about this issue and
        users will be asked to continue using Mindology.io services or complete
        logout. In case of violation of the rules by users Terms and conditions
        of services, and in the event of a private plaintiff regarding your
        performance, your information will be provided to legal authorities.
        Mindology.io may store a cookie on your computer and use it on your next
        visit to the Mindology.io site. You can change your registered
        information on the Mindology.io site. This information includes all the
        information that you have entered in the Mindology.io site when you
        subscribe to Mindology.io; Unless they have an account security
        criterion for authentication functionality such as email and last name,
        and… You can completely exit Mindology.io and all its services. In this
        case, some of your information is removed from the mindology.io and some
        of it is archived for at least a year. This information includes name,
        surname, email address, mobile phone number, sent messages and the like,
        but is not limited to the above. Restrictions on access to information
        Mindology.io restricts your employees' access to your information, and
        only employees who need to contact you directly to provide Mindology.io
        services or perform their duties will have access to this information.
        Mindology.io may store important information, such as passwords and
        passwords, encrypted in its databases to protect confidential
        information, so that if accessed unauthorized or unauthorized, this
        information may not be used by others; However, with the advancement of
        technology, we can not give you any guarantee in this regard. We hope we
        can guarantee that your personal information will be used to the highest
        security standards. Mindology.io will try to take all reasonable steps
        to secure any information that's available to you. Your personal
        information is also stored in secure networks, but unfortunately,
        despite the above technology and security devices, the security of any
        data transmission over the Internet can not be guaranteed 100%.
        Therefore, we can not guarantee with certainty that the information you
        send us will be safe during transmission (transmission) in any
        circumstances and in addition we can not be held responsible for any
        incidents resulting from unlawful access to your personal information.
        Accept. (Like ISPs accessing information sent from your computer)
        Mindology.io will not accept responsibility for the consequences of
        illegally accessing your personal information by a third party or group.
        Linked sites This site may link to other websites ("link sites"). Linked
        sites are not monitored by Mindology.io and Mindology.io is not
        responsible for them. You should note that the personal information you
        submit to the Linked Sites will not be overseen by the Mindology.io
        Privacy Policy, and we strongly recommend that you become aware of their
        Privacy System and their security policies. Mindology.io reserves the
        right to make changes to the text of this policy at any time without
        prior notice. Although all users are notified of the changes via e-mail,
        but users are required to be constantly informed of the changes by
        referring to this text and in case of opposition to it, request to
        disconnect their service or leave the Myndology site and go to Do not
        refer to it. Otherwise, all possible consequences will be borne by
        users. Questions and Suggestions If you have questions about these
        policies or have suggestions for changes and improvements, please
        contact the Mindology.io team using the contact form.
      </p>
    </b-col>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
    };
  },

  methods: {},
};
</script>
